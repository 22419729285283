.App {
  text-align: center;
}
a {
  text-decoration: none;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.MuiTabPanel-root {
  background-color: red;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
//.is-new-order {
//  max-height: 0;
//  transition: max-height 0.15s ease-out;
//  overflow: hidden;
//  background: red;
//}
.category-item {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:hover {
    .category-items__actions {
      opacity: 1;
      cursor: pointer;
    }
  }
}

.category-item__name {
  padding-top: 0.5rem;
}

.category-item__name,
.category-item__price {
  color: black;
  font-weight: bold;
}

.category-items__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.3rem;
  opacity: 0.5;
  transition: opacity 500ms;

  .category-items__actions-edit:hover {
    color: green;
  }
  .category-items__actions-delete:hover {
    color: red;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}